<template>
  <div class="pubic-main">
    <div class="login-pubic">
      <img src="/images/login-public.png" alt="" class="backPublic" />
      <img :src="login_url" alt="" class="backLogo">
    </div>
    <div class="from-box">
      <div>
        <span class="messages">
          {{app_title}}
        </span>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onMounted,ref,inject} from 'vue'
import csrfFetch from '../../packs/csrfFetch'
import { ElMessage } from "element-plus";


const props = defineProps({
  label: String
})
const app_title = ref('算法仓库')
const login_url = ref('/images/opencsg_login_logo.png')
const endpoint = inject('endpoint')
const busketName = inject('busketName')

const getData = async () => {
  const options = {
    method: 'get'
  }
  const response = await csrfFetch(`/configur/data?names=APP_TITLE,VUE_APP_LOGIN_LOGO_URL`, options)

  if (!response.ok) {
    response.json().then(data => {
      ElMessage({
        message: data.message,
        type: "warning",
      })
    })
  } else {
    response.json().then(res => {
      if(res.data.length) {
        const appTitle = res.data.find( x => x.name === 'APP_TITLE')?.value
        const loginUrl = res.data.find( x => x.name === 'VUE_APP_LOGIN_LOGO_URL')?.value
        if(appTitle) app_title.value = appTitle
        if(loginUrl) login_url.value = `${endpoint}/${busketName}/${loginUrl}`
      }
    })
  }
}

onMounted(() => {
  getData()
})

</script>
<style scoped>
.pubic-main {
  display: flex;
  width: 100%;
  height: 100%;
}

.login-pubic {
  width: calc(100% - 560px);
  height: 100%;
  position: relative;
}

.backPublic {
  width: 100%;
  height: 100%;
}

.from-box {
  height: 100%;
  width: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.messages {
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #1D2129;
  margin-bottom: 32px;
  display: inline-block;
}
.backLogo{
  position: absolute;
  top: 66px;
  left: 66px;
}
</style>