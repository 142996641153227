<template>
  <div class="mian-h max-w-[640px] m-auto">
    <el-form :model="form" ref="formRef" label-width="auto" class="configurForm">
      <el-form-item label="网站标题">
        <el-input v-model="form.appTitle" class="configurInput" placeholder="请输入网站标题">
          <template #append>
            <el-button title="确认修改" icon="EditPen" class="inputButton" type="primary"
              @click="editLable('APP_TITLE', 'appTitle')"></el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="网站copyright">
        <el-input v-model="form.copyright" class="configurInput" placeholder="请输入网站copyright">
          <template #append>
            <el-button title="确认修改" icon="EditPen" class="inputButton" type="primary"
              @click="editLable('APP_COPYRIGHT', 'copyright')"></el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="网站网备">
        <el-input v-model="form.filings" class="configurInput" placeholder="请输入网站网备">
          <template #append>
            <el-button title="确认修改" icon="EditPen" class="inputButton" type="primary"
              @click="editLable('APP_FILINGS', 'filings')"></el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="网站ICP">
        <el-input v-model="form.ICP" class="configurInput" placeholder="请输入网站ICP">
          <template #append>
            <el-button title="确认修改" icon="EditPen" class="inputButton" type="primary"
              @click="editLable('APP_IPC', 'ICP')"></el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="系统内logo">
        <div class="system-logo-box" @click="openUploadDialog('VUE_APP_LOGO_URL', 'logoUrl')">
          <img title="点击上传系统内logo" :src="form.logoUrl" class="favicon-logo" style="width: 101px;height: 26px;" />
        </div>
        <div class="text-class">
          仅支持.png格式,推荐尺寸（宽101 * 高26）
        </div>
      </el-form-item>
      <el-form-item label="登录页logo">
        <div class="system-logo-box" @click="openUploadDialog('VUE_APP_LOGIN_LOGO_URL', 'loginUrl')">
          <img title="点击上传登录页logo" :src="form.loginUrl" class="favicon-logo" style="width: 204px;height: 52px;" />
        </div>
        <div class="text-class">
          仅支持.png格式,推荐尺寸（宽204 * 高52）
        </div>
      </el-form-item>
      <el-form-item label="URL图标">
        <div class="system-logo-box" @click="openUploadDialog('VUE_APP_FAVICON', 'faviconUrl')">
          <img title="点击上传URL图标" :src="form.faviconUrl" style="width: 32px;height: 32px;" />
        </div>
        <div class="text-class">
          仅支持.png格式,推荐尺寸（宽32 * 高32）
        </div>
      </el-form-item>
    </el-form>
    <input ref="fileInput" accept=".png" type="file" class="hidden" @change="previewImage" />
  </div>
</template>
<script setup>
import { ref, inject, onMounted } from 'vue'
import csrfFetch from '../../packs/csrfFetch'
import { ElMessage } from "element-plus";


const fileInput = ref(null)
const uploadName = ref(null)
const formName = ref(null)
const dataList = ref([])
const endpoint = inject('endpoint')
const busketName = inject('busketName')
const form = ref({
  appTitle: null,
  copyright: null,
  filings: null,
  ICP: null,
  faviconUrl: null,
  loginUrl: null,
  logoUrl: null
})


const getData = async () => {
  const options = {
    method: 'get'
  }
  const response = await csrfFetch(`/configur/data?names=APP_TITLE,APP_IPC,APP_FILINGS,APP_COPYRIGHT,VUE_APP_LOGO_URL,VUE_APP_LOGIN_LOGO_URL,VUE_APP_FAVICON`, options)
  if (!response.ok) {
    response.json().then(data => {
      ElMessage({
        message: data.message,
        type: "warning",
      })
    })
  } else {
    response.json().then(res => {
      dataList.value = res.data
      setFormValue()
    })
  }
}

const setFormValue = () => {
  const getListData = (name) => {
    return dataList.value.find(x => x.name === name)?.value
  }

  form.value.appTitle = getListData('APP_TITLE')
  form.value.copyright = getListData('APP_COPYRIGHT')
  form.value.filings = getListData('APP_FILINGS')
  form.value.ICP = getListData('APP_IPC')
  form.value.faviconUrl = `${endpoint}/${busketName}/${getListData('VUE_APP_FAVICON')}`

  form.value.loginUrl = `${endpoint}/${busketName}/${getListData('VUE_APP_LOGIN_LOGO_URL')}`

  form.value.logoUrl = `${endpoint}/${busketName}/${getListData('VUE_APP_LOGO_URL')}`
}

const editLable = (label, value) => {
  setData(label, form.value[value])
}

const openUploadDialog = (label, form_name) => {
  formName.value = form_name
  uploadName.value = label
  fileInput.value.click()
}
const previewImage = () => {
  imgData = URL.createObjectURL(fileInput.value.files[0]);
  form.value[formName.value] = imgData
  uploadImg(fileInput.value.files[0])
}

const uploadImg = async (img) => {
  const formData = new FormData();
  formData.append('name', uploadName.value);
  formData.append('img', img);
  const options = {
    method: 'post',
    body: formData,
  }
  api(`/configur/upload`, options)
}

const setData = async (label, value) => {
  const formData = new FormData();
  formData.append('name', label);
  formData.append('value', value);
  const options = {
    method: 'post',
    body: formData,
  }
  api(`/configur/update`, options)
}

const api = async (url, options) => {
  const response = await csrfFetch(url, options)
  if (!response.ok) {
    response.json().then(data => {
      ElMessage({
        message: data.message,
        type: "warning",
      })
    })
  } else {
    ElMessage({
      message: '修改成功',
      type: "success",
    });
    // 处理成功响应
  }
}

onMounted(() => {
  getData()
})
</script>
<style scoped>
.configurInput /deep/ .el-input__wrapper {
  border: none;
}


.configurInput /deep/ .el-input-group__append .el-button.el-button--primary {
  background-color: #5872E5;
  border-color: #5872E5;
  color: #fff;
  font-size: 14px;
}

.system-logo-box {
  background: #5872E5;
  cursor: pointer;
  display: inline-block;
  /* height: 50px; */
  padding: 13px;
}

.system-logo-box .system-logo {
  height: 100%;
  width: auto;
}

.text-class {
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5;
}

.configurForm /deep/ .el-form-item .el-form-item__content {
  display: block;
}
</style>