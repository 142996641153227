import { reactive } from 'vue';
import csrfFetch from './csrfFetch'
import { ElMessage } from "element-plus";

export const state = reactive({
  ICP: localStorage.getItem('ICP'),
  filings: localStorage.getItem('filings'),
  copyright: localStorage.getItem('copyright'),
  logoUrl: localStorage.getItem('logoUrl')
});

export const getLogoData = async (endpoint,busketName) => {
  const options = {
    method: 'get'
  }
  const response = await csrfFetch(`/configur/data?names=APP_COPYRIGHT,APP_FILINGS,APP_IPC,VUE_APP_FAVICON,VUE_APP_LOGO_URL`, options)
  if (!response.ok) {
    const error = response.json()
    ElMessage({
      message: error.message,
      type: "warning",
    })
  } else {
    const res = await response.json()
    // if (res.data.length) {
      const getData = (name) => {
        return res.data.find((d) => d.name === name)?.value;
      };
      const favicon = getData('VUE_APP_FAVICON');
      const link = document.querySelector("link[rel*='icon']");
      link.href = favicon ? `${endpoint}/${busketName}/${favicon}` : '/images/favicon.png';
      const ICP = getData('APP_IPC') || '津ICP备 120xxxxxx号'
      const filings = getData('APP_FILINGS') || '津公网备 120xxxxxxxxxx号'
      const copyright = getData('APP_COPYRIGHT') || 'copyright©2024 xxxxxxxx有限公司'
      const logoUrl = getData('VUE_APP_LOGO_URL') ? `${endpoint}/${busketName}/${getData('VUE_APP_LOGO_URL')}` : '/images/opencsg_logo.png'
      // 本地缓存
      localStorage.setItem('ICP', ICP);
      localStorage.setItem('filings', filings);
      localStorage.setItem('copyright', copyright);
      localStorage.setItem('logoUrl', logoUrl);
      state.ICP = ICP
      state.filings = filings
      state.copyright = copyright
      state.logoUrl = logoUrl
    // }
  }
}